<template>
  <b-alert
    :show="showAlert"
    @dismissed="handleAlertDismissed"
    dismissible
    fade
    variant="info"
    class="pb-3 mb-0 fixed-bottom bottom-0 fs-12px"
  >
    <strong>Your privacy is important to us. </strong><br />We use cookies and
    similar technologies which are essential for our website and our mobile app
    (collectively, the "Service") to function. We and third parties may also use
    non-essential cookies and similar technologies to collect and analyze usage,
    optimize our services, personalize content, tailor and measure ads, keep
    this site secure, and for any other uses as set forth in our Privacy Policy.
    <a id="fides-modal-link" href="#" class="text-underline">Manage Cookies</a>
  </b-alert>
</template>

<script>
export default {
  name: 'CookieDisclaimer',
  data() {
    return { showAlert: true }
  },
  mounted() {
    if (localStorage.getItem('cd-cookie-disclaimer-alert')) {
      this.showAlert = false
    }
  },
  methods: {
    handleAlertDismissed: function() {
      localStorage.setItem('cd-cookie-disclaimer-alert', true)
    },
  },
}
</script>

<style scoped>
.fs-12px {
  font-size: 12px;
}
.text-underline {
  text-decoration: underline;
}
</style>
