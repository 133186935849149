<template>
  <div>
    <script
      v-for="data in schemaData"
      :key="data['@type']"
      type="application/ld+json"
    >
      {{data}}
    </script>
  </div>
</template>

<script>
export default {
  name: 'Schema',
  props: ['schemaData'],
}
</script>
