<template>
  <li v-if="navItem" class="nav-item px-3 py-2">
    <a
      @click="sponsorCheck"
      class="nav-link signup-link"
      :class="{
        'font-weight-semibold text-primary': emphasis,
        'sub-active': signupIsActive,
      }"
    >
      <slot />
    </a>
  </li>
  <b-button
    v-else
    @click="sponsorCheck"
    :pill="pill"
    :size="size"
    :variant="variant"
    :class="{ 'signup-link': true }"
  >
    <slot />
  </b-button>
</template>

<script>
export default {
  name: 'SignupLinkButton',
  props: {
    to: {
      type: [String, Object],
      default: '',
    },
    pill: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'secondary',
    },
    navItem: {
      type: Boolean,
      default: false,
    },
    emphasis: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'large',
    },
  },
  computed: {
    useSignupWidget: function() {
      return this.$store.getters['member/isSignupWidgetVisit']
    },
    trackingParams: function() {
      return this.$store.getters['member/getTrackingForLink']
    },
    signupLink: function() {
      return this.landingPage && !this.$store.state.member?.affiliate_id
        ? `${this.$store.getters['member/getSignupLink']}&AID=special-offer-organic`
        : this.$store.getters['member/getSignupLink']
    },
    replaceSignupLink: function() {
      // replace the signup link (with member site url) if the pid isn't default and isn't a radio referral
      // return this.landingPage || this.$store.getters['member/isDirectSignup']
      return this.to.name === 'EmployerSignup' ||
        this.to.name === 'LenderSignup'
        ? false
        : true // CD-2828 Remove advocate signup page choice from flow - everything but lender and employer signup should all go direct to the member funnel
    },
    showSponsorModal: function() {
      return this.$store.getters['member/isSponsorModalRequired']
    },
    signupIsActive: function() {
      return this.$route.meta.signup
    },
    landingPage: function() {
      return this.$route.name === 'LandingPage'
    },
  },
  methods: {
    sponsorCheck() {
      if (this.showSponsorModal) {
        this.$bvModal.show('sponsor-modal')
      } else if (this.useSignupWidget) {
        this.$router.push(`/try-scoremaster/?${this.trackingParams}`)
      } else {
        if (this.replaceSignupLink) window.location = this.signupLink
        else this.$router.push(this.to)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.signup-link {
  cursor: pointer;
  width: 100%;

  &.btn-sm {
    font-size: 14px;
  }
}
</style>
