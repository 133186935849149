<template>
  <div v-if="!employeeBenefitsPage" id="sidebar" class="pt-3">
    <b-nav vertical class="font-weight-semibold text-text-black">
      <NavLink title="How It Works" :to="{ name: 'HowItWorks' }" />
      <NavLink title="Consumers" :to="{ name: 'IndividualBenefits' }" />
      <NavLink title="Lenders" :to="{ name: 'LenderBenefits' }" />
      <NavLink title="Employee Benefit" :to="{ name: 'EmployeeBenefits' }" />
      <NavLink title="Features" :to="{ name: 'Features' }" />
      <NavLink
        v-if="showMembershipLink"
        title="Membership"
        :to="{ name: 'Membership' }"
      />
      <NavLink title="Blog" to="/blog/" />
      <SignupLinkButton :to="{ name: 'IndividualSignup' }" navItem
        >Sign Up Now!</SignupLinkButton
      >
      <NavLink externalLink title="Log In" :to="loginLink" />
    </b-nav>
    <hr class="mx-3" />
    <b-nav vertical class="text-black font-weight-light">
      <NavLink title="About Us" to="/about-us/" />
      <NavLink
        externalLink
        title="Contact Us"
        :to="`${memberUrl}help/contact-us/`"
      />
      <NavLink
        externalLink
        title="FAQ"
        :to="`${memberUrl}help/resources/frequently-asked-questions.htm`"
      />
      <NavLink
        title="Check Your Credit Score"
        :to="{ name: 'CheckCreditScore' }"
      />
      <NavLink
        title="Boost Your Credit Score"
        :to="{ name: 'BoostCreditScore' }"
      />
      <NavLink
        title="Credit Monitoring"
        :to="{ name: 'CreditScoreMonitoring' }"
      />
      <NavLink
        externalLink
        title="Security"
        :to="`${memberUrl}help/resources/security.htm`"
      />
      <NavLink title="Sitemap" to="/sitemap" />
    </b-nav>
  </div>
  <div v-else id="sidebar" class="pt-3">
    <b-nav vertical class="font-weight-semibold text-text-black">
      <NavLink title="For Employers" :to="{ name: 'EmployeeBenefits' }" />
      <NavLink title="For Brokers" :to="{ name: 'EmployeeBenefitsBrokers' }" />
      <NavLink
        title="For Providers"
        :to="{ name: 'EmployeeBenefitsProviders' }"
      />
      <NavLink title="Blog" to="/blog/" />
      <NavLink title="FAQ" :to="{ name: 'EmployeeBenefitsFaq' }" />
      <NavLink title="Contact Us" :to="{ name: 'EmployeeBenefitContact' }" />
    </b-nav>
  </div>
</template>

<script>
import NavLink from '@/components/navigation/NavLink'

export default {
  name: 'Sidebar',
  data() {
    return {
      memberUrl: process.env.VUE_APP_MEMBER_URL,
    }
  },
  components: {
    NavLink,
  },
  computed: {
    loginLink: function() {
      return this.$store.getters['member/getLoginLink']
    },
    cobrand: function() {
      return this.$store.getters['member/getCobrand']
    },
    isCobrand: function() {
      return Object.keys(this.cobrand).length
    },
    // we only show membership link if the visitor is on a cobrand with a sponsored plan
    showMembershipLink: function() {
      return this.$store.getters['member/isSponsorModalRequired']
    },
    employeeBenefitsPage: function() {
      return this.$route.meta.employeeBenefits
    },
  },
}
</script>
