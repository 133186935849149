'use strict'

Object.defineProperty(exports, '__esModule', {
  value: true,
})

exports.default = void 0

var _default = {
  bind: function(el, binding, vNode) {
    const handler = e => {
      if (
        !el.contains(e.target) &&
        el !== e.target &&
        !e.target.classList.contains('navbar-toggler') &&
        !e.target.classList.contains('navbar-toggler-icon')
      ) {
        if (vNode.context[binding.expression])
          vNode.context[binding.expression] = false
      }
    }
    el.out = handler
    document.addEventListener('click', handler)
  },

  unbind: function(el) {
    document.removeEventListener('click', el.out)
    el.out = null
  },
}

exports.default = _default
