const getters = {
  isLoading: state => state.status === 'loading',
  isPIDLoaded: state => state.pid_loaded,
  isDefaultPID: state => state.pid === process.env.VUE_APP_DEFAULT_PID,
  getPID: state => state.pid,
  getAdvocacyPID: state => state.advocacy_pid,
  getCobrand: state => state.cobrand,
  getMemberPlans: state => state.member_plans,
  getMemberLink: state => state.member_link,
  getTrackingToken: state => state.tracking_token,
  isValidQL: state => state.valid_quicklink,
  getTrackingForLink: state => {
    let args = `PID=${state.pid}`
    if (localStorage.getItem('aid'))
      args += `&AID=${localStorage.getItem('aid')}`
    if (localStorage.getItem('adid'))
      args += `&ADID=${localStorage.getItem('adid')}`
    if (localStorage.getItem('tid'))
      args += `&TID=${localStorage.getItem('tid')}`
    if (localStorage.getItem('sid'))
      args += `&SID=${localStorage.getItem('sid')}`
    if (localStorage.getItem('cid'))
      args += `&CID=${localStorage.getItem('cid')}`
    return args
  },
  getSignupLink: state => {
    let args = `PID=${state.pid}`
    if (localStorage.getItem('aid'))
      args += `&AID=${localStorage.getItem('aid')}`
    if (localStorage.getItem('adid'))
      args += `&ADID=${localStorage.getItem('adid')}`
    if (localStorage.getItem('tid'))
      args += `&TID=${localStorage.getItem('tid')}`
    if (localStorage.getItem('sid'))
      args += `&SID=${localStorage.getItem('sid')}`
    if (localStorage.getItem('cid'))
      args += `&CID=${localStorage.getItem('cid')}`
    return `${state.member_link}join/?${args}`
  },
  getLoginLink: state => `${state.member_link}login/?pid=${state.pid}`,
  isDirectSignup: state => {
    if (state.pid === process.env.VUE_APP_DEFAULT_PID) return false // default pid means no cobrand, use sort page for signup
    if (state.advocacy_pid !== '23736') return false
    // non default pid, non default advocacy pid means radio cobrand, use sort for signup
    else return true // else non default pid with default advocacy means normal cobrand, use direct link for signup action
  },
  isSponsorModalRequired: (/*state*/) => {
    // if (state.member_plans.signupPlanTypes)
    //   return !!state.member_plans.signupPlanTypes.find(
    //     plan => plan === 'SPONSORED'
    //   )
    //else
    return false
  },
  getDefaultSignupPlan: state =>
    state.member_plans.signupPlans.find(x => x.isDefaultPlan),
  getDefaultOfferType: state =>
    state.member_plans.signupPlans.find(x => x.isDefaultPlan).offerType,
  getDefaultPlanType: state =>
    state.member_plans.signupPlans.find(x => x.isDefaultPlan).planType,
  getTrackingData: (state, getters) => {
    return {
      product_code: 'sm',
      company: 'Consumer Direct',
      ...(getters.getDefaultPlanType && {
        package_type: getters.getDefaultPlanType,
      }),
      ...(getters.getDefaultOfferType && {
        offer_type: getters.getDefaultOfferType,
      }),
      ...(state.tracking && { anonymousId: state.tracking }),
      ...(state.pid && { publisher_idn: state.pid }),
      ...(state.affiliate_id && { affiliate_idn: state.affiliate_id }),
      ...(state.ad_creative_id && { ad_creative_idn: state.ad_creative_id }),
      ...(state.campaign_id && { campaign_idn: state.campaign_id }),
      ...(state.site_id && { site_idn: state.site_id }),
      ...(state.template_id && { template_idn: state.template_id }),
    }
  },
  getUtmTrackingData: state => state.campaign,
  isSignupWidgetVisit: state => state.pid === '25228',
}

export default getters
