import Api from '@/services/Api'

export default {
  getPosts(category, page) {
    let query = `&page=${page}&per_page=6`
    if (category) {
      query += `&categories=${category}`
    }
    return Api.blog().get(`posts/?${query}`)
  },
  getCategories() {
    return Api.blog().get(`categories?hide_empty=true`)
  },
  getPost(id) {
    return Api.blog().get(`posts/${id}`)
  },
  getMedia(id) {
    return Api.blog().get(`media/${id}`)
  },
  getAuthors() {
    return Api.blog().get(`users`)
  },
}
