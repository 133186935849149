import Vue from 'vue'
import Vuex from 'vuex'
import member from './modules/member'
import blog from './modules/blog'
import metaData from './modules/metaData'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    member,
    blog,
    metaData,
  },
})
