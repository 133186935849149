import { render, staticRenderFns } from "./Cobrand.vue?vue&type=template&id=106deeab&scoped=true&"
import script from "./Cobrand.vue?vue&type=script&lang=js&"
export * from "./Cobrand.vue?vue&type=script&lang=js&"
import style0 from "./Cobrand.vue?vue&type=style&index=0&id=106deeab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "106deeab",
  null
  
)

export default component.exports