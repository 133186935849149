// Global Components import - importing them here keeps our main.js file cleaner
import Vue from 'vue'

import Loader from '@/components/common/Loader'
import MiniLoader from '@/components/common/MiniLoader'
import FullWidthImageHero from '@/components/common/FullWidthImageHero'
import SignupLinkButton from '@/components/common/SignupLinkButton'

Vue.component('Loader', Loader)
Vue.component('MiniLoader', MiniLoader)
Vue.component('FullWidthImageHero', FullWidthImageHero)
Vue.component('SignupLinkButton', SignupLinkButton)
