const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      pageTitle:
        'Best Online Credit Score Simulator Software | Check All Three Scores',
      pageDescription:
        "Need help getting a good credit score? ScoreMaster®'s online estimator gives you a personalized plan to add points to your score fast. Get your trial today!",
    },
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
  },
  {
    path: '/consumer-credit-report-benefits/',
    name: 'IndividualBenefits',
    component: () =>
      import(/* webpackChunkName: "signup" */ '@/views/IndividualBenefits'),
    meta: {
      benefits: true,
      pageTitle:
        'Best Consumer Credit Report & Credit Score Reporting Services',
      pageDescription:
        'Get a better credit score quickly! Our service helps you monitor & take action on your credit and money to get  better loan rates & lower down payments.',
    },
  },
  {
    path: '/lender-benefits/',
    name: 'LenderBenefits',
    component: () =>
      import(/* webpackChunkName: "signup" */ '@/views/LenderBenefits'),
    meta: {
      benefits: true,
      pageTitle:
        'Realtor Software for Marketing | Mortgage Broker Technology Tools',
      pageDescription:
        'Real Estate Agents and Mortgage Brokers love using ScoreMaster® to qualify more buyers, increase revenue and gain an edge over the competition.',
    },
  },
  {
    path: '/employee-benefits/',
    name: 'EmployeeBenefits',
    component: () =>
      import(
        /* webpackChunkName: "signup" */ '@/views/employee-benefits/EmployeeBenefits'
      ),
    meta: {
      employeeBenefits: true,
      pageTitle: 'ScoreMaster® - Employee Financial Wellness',
      pageDescription:
        'The Employee Financial Stress Reliever - ScoreMaster® makes understanding credit easier and privacy protection painless. Add ScoreMaster® to your Employee Benefits package today. ',
    },
  },
  {
    path: '/employee-benefits/faq/',
    name: 'EmployeeBenefitsFaq',
    component: () =>
      import(
        /* webpackChunkName: "signup" */ '@/views/employee-benefits/EmployeeBenefitsFaq'
      ),
    meta: {
      emeployeeBenefitsResources: true,
      employeeBenefits: true,
      pageTitle: 'ScoreMaster® - Employee Financial Wellness FAQ',
      pageDescription:
        'Browse Through the FAQs. Find Answers To Your Questions.',
    },
  },
  {
    path: '/employee-benefits/employers/',
    name: 'EmployeeBenefitsEmployers',
    component: () =>
      import(
        /* webpackChunkName: "signup" */ '@/views/employee-benefits/EmployeeBenefitsEmployers'
      ),
    meta: {
      emeployeeBenefitsSolutions: true,
      employeeBenefits: true,
      pageTitle: 'ScoreMaster® - Employee Financial Wellness',
      pageDescription:
        'ScoreMaster® helps employers decrease wage pressure and increase employee financial wellness without breaking the bank.',
    },
  },
  {
    path: '/employee-benefits/brokers/',
    name: 'EmployeeBenefitsBrokers',
    component: () =>
      import(
        /* webpackChunkName: "signup" */ '@/views/employee-benefits/EmployeeBenefitsBrokers'
      ),
    meta: {
      emeployeeBenefitsSolutions: true,
      employeeBenefits: true,
      pageTitle: 'ScoreMaster® - Employee Financial Wellness',
      pageDescription:
        'ScoreMaster® helps brokers provide lasting value to employers with an all-in-one employee financial wellness solution to complete their benefits package. Watch our demo video to learn more.',
    },
  },
  {
    path: '/employee-benefits/providers/',
    name: 'EmployeeBenefitsProviders',
    component: () =>
      import(
        /* webpackChunkName: "signup" */ '@/views/employee-benefits/EmployeeBenefitsProviders'
      ),
    meta: {
      emeployeeBenefitsSolutions: true,
      employeeBenefits: true,
      pageTitle: 'ScoreMaster® - Employee Financial Wellness',
      pageDescription:
        'Enhance your financial wellness offering with patented widgets from ScoreMaster®, a new technology giving people control over their credit score and privacy',
    },
  },
  {
    path: '/lender-signup/',
    name: 'LenderSignup',
    component: () =>
      import(/* webpackChunkName: "signup" */ '@/views/LenderSignup'),
    meta: {
      signup: true,
      pageTitle: 'Sign Up as a Lender or Real Estate Agent | ScoreMaster®',
      pageDescription:
        "Use ScoreMaster® first! We help lenders, brokers, & real estate agents maximize the clients' credit scores!",
    },
  },
  {
    path: '/consumer-signup/',
    name: 'IndividualSignup',
    component: () =>
      import(/* webpackChunkName: "signup" */ '@/views/IndividualSignup'),
    meta: {
      signup: true,
      pageTitle: 'Sign Up for ScoreMaster®',
      pageDescription:
        'Start using ScoreMaster® to improve your credit score - the first step to better mortgage rates and loans!',
    },
  },
  {
    path: '/employee-benefits/contact/',
    name: 'EmployeeBenefitContact',
    component: () =>
      import(
        /* webpackChunkName: "signup" */ '@/views/employee-benefits/EmployeeContact'
      ),
    meta: {
      signup: true,
      employeeBenefits: true,
      pageTitle: ' ScoreMaster® - Employee Financial Wellness - Contact',
      pageDescription:
        "Contact Us. We're committed to providing you with the best possible customer service. Call us today at (877) 210-0180.",
    },
  },
  {
    path: '/get-started/',
    name: 'GetStarted',
    meta: {
      pageTitle: 'Get Started | ScoreMaster®',
      pageDescription:
        'Use ScoreMaster® first - a win win solution for borrowers, lenders, real estate agents, and companies.',
    },
    component: () =>
      import(/* webpackChunkName: "signup" */ '@/views/GetStarted'),
  },
  {
    path: '/how-it-works/',
    name: 'HowItWorks',
    meta: {
      pageTitle: 'How to Find Your Credit Score Fast! ScoreMaster®',
      pageDescription:
        'If you’re asking “how to find my credit score” click here. We help you quickly add points to your score & take ownership of your credit & finances.',
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/HowItWorks'),
  },
  {
    path: '/features/',
    name: 'Features',
    meta: {
      pageTitle: 'ScoreMaster® Features for the Best Credit Reporting Service',
      pageDescription:
        'ScoreMaster® is your solution if you’re looking for credit reporting services  online to take ownership of your credit score. Get your trial today.',
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/Features'),
  },
  {
    path: '/what-you-get/',
    name: 'Membership',
    meta: {
      pageTitle: 'Membership | ScoreMaster®',
      pageDescription:
        'Be a member of ScoreMaster® now! Monitor and improve your credit score in a timely manner. With Scoremaster®, you can get a better loan rate!',
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Membership'),
  },
  {
    path: '/special-offer/',
    name: 'SpecialOfferPage',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/LandingPage'),
    meta: {
      pageTitle: 'ScoreMaster® | The Fastest Way to Your Best Credit Score™',
      pageDescription:
        ' Our personalized plans help you get your best credit score in a short time. Claim your trial',
    },
  },
  {
    path: '/thank-you/',
    name: 'ThankYou',
    meta: {
      pageTitle: 'Thank you! | ScoreMaster®',
      pageDescription:
        'Our personalized advice improves credit score in a short time. A win-win solution for lenders, borrowers, & brokers!',
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/ThankYou'),
  },
  {
    path: '/blog/',
    name: 'BlogHome',
    meta: {
      pageTitle: 'Resources: Credit Scores & Personal Finance | ScoreMaster®',
      pageDescription:
        'Learn more about credit score and why borrowers, home buyers, lenders, and brokers should care about it!',
    },
    component: () => import(/* webpackChunkName: "blog" */ '@/views/blog/Home'),
  },
  {
    path: '/blog/category/:id/:slug',
    name: 'BlogList',
    meta: {
      pageTitle: 'Resources: Credit Scores & Personal Finance | ScoreMaster®',
      pageDescription:
        'Our personalized plan helps you get your best credit score in a short time. A win-win solutions for consumers, lenders & real estate agents! Get the ScoreMaster® app now!',
    },
    component: () => import(/* webpackChunkName: "blog" */ '@/views/blog/List'),
  },
  {
    path: '/blog/view/:id/:slug',
    name: 'BlogPost',
    meta: {
      pageTitle: 'Resources: Credit Scores & Personal Finance | ScoreMaster®',
      pageDescription:
        'Our personalized plan helps you get your best credit score in a short time. A win-win solutions for consumers, lenders & real estate agents! Get the ScoreMaster® app now!',
    },
    component: () => import(/* webpackChunkName: "blog" */ '@/views/blog/Post'),
  },
  {
    path: '/drlaura/',
    name: 'DrLauraLanding',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/radio/DrLauraLanding'),
    meta: {
      ql: true,
      landing: true,
      pageTitle: 'ScoreMaster® | The Fastest Way to Your Best Credit Score™',
      pageDescription:
        'Our personalized plan helps you get your best credit score in a short time. A win-win solutions for consumers, lenders & real estate agents! Get the ScoreMaster® app now!',
    },
  },
  {
    path: '/tim/',
    name: 'KFILanding',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/radio/KFILanding'),
    meta: {
      ql: true,
      landing: true,
      pageTitle: 'ScoreMaster® | The Fastest Way to Your Best Credit Score™',
      pageDescription:
        'Our personalized plan helps you get your best credit score in a short time. A win-win solutions for consumers, lenders & real estate agents! Get the ScoreMaster® app now!',
    },
  },
  {
    path: '/patty/',
    name: 'PattyLanding',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/radio/PattyLanding'),
    meta: {
      ql: true,
      landing: true,
      pageTitle: 'ScoreMaster® | The Fastest Way to Your Best Credit Score™',
      pageDescription:
        'Our personalized plan helps you get your best credit score in a short time. A win-win solutions for consumers, lenders & real estate agents! Get the ScoreMaster® app now!',
    },
  },
  {
    path: '/blaze/',
    name: 'BlazeLanding',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/radio/BlazeLanding'),
    meta: {
      ql: true,
      landing: true,
      pageTitle: 'ScoreMaster® | The Fastest Way to Your Best Credit Score™',
      pageDescription:
        'Our personalized plan helps you get your best credit score in a short time. A win-win solutions for consumers, lenders & real estate agents! Get the ScoreMaster® app now!',
    },
  },
  {
    path: '/phil/',
    name: 'PhilLanding',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/radio/PhilLanding'),
    meta: {
      ql: true,
      landing: true,
      pageTitle: 'ScoreMaster® | The Fastest Way to Your Best Credit Score™',
      pageDescription:
        'Our personalized plan helps you get your best credit score in a short time. A win-win solutions for consumers, lenders & real estate agents! Get the ScoreMaster® app now!',
    },
  },
  {
    path: '/about-us/',
    name: 'AboutUs',
    meta: {
      pageTitle: 'ScoreMaster® | The Fastest Way to Your Best Credit Score™',
      pageDescription:
        'Our personalized plan helps you get your best credit score in a short time. A win-win solutions for consumers, lenders & real estate agents! Get the ScoreMaster® app now!',
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutUs'),
  },
  {
    path: '/check-credit-score/',
    name: 'CheckCreditScore',
    meta: {
      pageTitle: 'How to Check Your Credit Score Online with ScoreMaster®',
      pageDescription:
        'ScoreMaster® is the best way to check your credit score online. Our tool helps protect, understand, and improve your credit quickly.',
    },
    component: () =>
      import(
        /* webpackChunkName: "checkCreditScore" */
        '@/views/CheckCreditScore'
      ),
  },
  {
    path: '/boost-credit-score/',
    name: 'BoostCreditScore',
    meta: {
      pageTitle:
        'How to Boost Credit Score Fast with ScoreMaster® Credit Tools',
      pageDescription:
        'Use ScoreMaster® to boost your credit score fast! Our credit booster gives information to help you focus and target specific needs to raise your score quickly.',
    },
    component: () =>
      import(
        /* webpackChunkName: "checkCreditScore" */
        '@/views/BoostCreditScore'
      ),
  },
  {
    path: '/credit-score-monitoring/',
    name: 'CreditScoreMonitoring',
    meta: {
      pageTitle: 'Best Credit Score Monitoring Software Services| ScoreMaster®',
      pageDescription:
        'Sign up today with ScoreMaster® for best credit monitoring service and real-time alerts! More tools to help you get more points and keep your best score!',
    },
    component: () =>
      import(
        /* webpackChunkName: "checkCreditScore" */
        '@/views/CreditScoreMonitoring'
      ),
  },
  {
    path: '/sitemap/',
    name: 'Sitemap',
    meta: {
      pageTitle: 'Sitemap | ScoreMaster®',
      pageDescription:
        'Our personalized advice improves credit score in a short time. A win-win solution for lenders, borrowers, & brokers!',
    },
    component: () => import(/* webpackChunkName: "about" */ '@/views/Sitemap'),
  },
  // Redirects
  {
    path: '/borrower-benefits/',
    redirect: '/consumer-credit-report-benefits/',
  },
  {
    path: '/consumer-benefits/',
    redirect: '/consumer-credit-report-benefits/',
  },
  {
    path: '/individual-benefits',
    redirect: '/consumer-credit-report-benefits/',
  },
  {
    path: '/employer-signup/',
    redirect: '/employee-benefits/contact/',
  },
  { path: '/benefits', redirect: '/employee-benefits/' },
  { path: '/employer-signup', redirect: '/employee-benefits/contact' },
  { path: '/borrower-signup', redirect: '/consumer-signup/' },
  { path: '/individual-signup', redirect: '/consumer-signup/' },
  { path: '/allie', redirect: '/ali' },
  { path: '/ally', redirect: '/ali' },
  { path: '/jessie', redirect: '/jesse' },
  { path: '/doctorlaura', redirect: '/drlaura' },
  { path: '/laura', redirect: '/drlaura' },
  { path: '/t', redirect: '/tea' },
  { path: '/tammimac', redirect: '/bobb' },
  {
    path: '/free-trial/',
    name: 'DigitalMarketingLandingPage',
    meta: {
      pageTitle: 'Try ScoreMaster® for Free',
      pageDescription:
        'Create your free 14 day trial account and get access to exclusive, interactive credit tools that help raise & maintain your credit score directly through our platform.',
    },
    component: () =>
      import(
        /* webpackChunkName: "digitalMarketingLandingPage" */
        '@/views/DigitalMarketingLandingPage'
      ),
  },
  {
    path: '/try-scoremaster/',
    name: 'DigitalMarketingLandingPage2',
    meta: {
      pageTitle: 'Try ScoreMaster® for Free',
      pageDescription:
        'Create your free 14 day trial account and get access to exclusive, interactive credit tools that help raise & maintain your credit score directly through our platform.',
    },
    component: () =>
      import(
        /* webpackChunkName: "digitalMarketingLandingPage" */
        '@/views/DigitalMarketingLandingPage2'
      ),
  },
  {
    path: '*',
    name: 'LandingPage',
    component: () => import(/* webpackChunkName: "about" */ '@/views/404'),
    meta: {
      ql: true,
      pageTitle: 'Best Online Credit Score Simulator Software | ScoreMaster®',
      pageDescription:
        'Our personalized plan helps you get your best credit score in a short time. A win-win solutions for consumers, lenders & real estate agents! Get the ScoreMaster® app now!',
    },
  },
]

module.exports = routes
