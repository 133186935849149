const state = {
  status: 'loading',
  pid: '',
  advocacy_pid: '',
  tracking_token: '',
  tracking: '',
  pid_loaded: false,
  valid_quicklink: null,
  cobrand: {},
  member_plans: {},
  member_link: process.env.VUE_APP_MEMBER_URL,
  pageviewQueue: [],
  campaign: {},
  landing_pid: '62954',
  advocacy_lookup: {
    /**
             * Advocacy lookup table because there's no way in SL currently to associate a second PID with the first and advocacy signups should use a different PID
              [
                ${Default PID} : ${Advocate PID}
              ]
             *
            **/
    '11270': '98628', // KFI Radio
    '38253': '54945', // Madrivo
    '26797': '38339', // Dr Laura
    '62293': '77637', // Sirius XM News
    '26168': '27735', // Patty
    '24827': '57439', // Woody
    '44087': '63261', // Bill
    '89850': '72063', // Blaze
    '16699': '97093', // Phil
    '88102': '95459', // Link Building
    '68998': '56186', // LinkedIn
    '84062': '14376', // Instagram
    '79368': '61424', // Facebook
    '66388': '58073', // YouTube
    '33961': '58686', // Radio
    '88666': '80937', // Adwords
    '48032': '29674', // Lauren
    '65872': '45719', // News
    '95179': '38344', // Pat
    '95584': '68728', // Steve
    '48462': '35301', // Graham
    '61525': '96808', // Stephanie
    '51659': '26101', // Paige Renee
    '19162': '53490', // Rush Limbaugh
    '42090': '68235', // Jillian Barberie
    '56278': '10617', // Pandora
    '43982': '62641', // Blog
    '42794': '54297', // KFI
    '30331': '47667', // Radio H - Megyn Kelly
    '19884': '38048', // Radio H – KDFD Denver
    '53311': '82561', // Radio H – Jesse Kelly
    '22588': '36511', // CBS /cbs
    '90360': '90967', // Nancy Grace /nancy
    '95928': '36578', // Stu does America
    '27258': '45389', // Rick & Bubba University
    '41522': '92189', // Gina Grad with KFI
    '45988': '57466', // Tori & Jenny
    '40610': '98071', // KFC Radio
    '54455': '15396', // KOIT /holiday
    '18770': '97581', // Newt Gingrich /newt
    '32820': '67080', // John Solomon /justnews
    '53583': '30337', // Sirius XM /benefits
    '50393': '35792', // Duck Call Room /duck
    '33071': '59523', // Chad Prather /chad
    '50999': '75606', // Elijah Schaffer /offense
    '12601': '39410', // Petros & Money
    '47131': '63070', // Andrew Klavan
    '70433': '37771', // Allie Stuckey
    '31654': '56500', // Frank and Heidi
    '14347': '81176', // Sarah Silverman
    '51409': '18413', // Jim Cornette
    '47097': '97997', // Robert Kelly
    '52479': '33891', // Ads - Various Networks (Display, Search, Cross Network, Audience Network)
    '16910': '97363', // TEA-GIF /tea
    '28826': '47261', // Morning Invest /invest
    '61977': '71041', // No Chaser /nochaser
    '45578': '84403', // Saving with Steve
    '76496': '43198', // Talking Sopranos /talking
    '58102': '41031', // El Terrible /laraza
    '38410': '10608', // Mayim Bialik's Breakdown /breakdown
    '19229': '69624', // Old Man & The Three
    '50351': '20751', // The Patdown /patdown
    '15024': '78110', // Investing in Real Estate /investing
    '38652': '15686', // Bigger Pockets Real Estate /biggerpockets
    '14492': '82294', // Pour Minds /pourminds
  },
}

export default state
