<template>
  <div class="ml-auto d-none d-xl-flex">
    <b-navbar-nav
      v-if="landingPage"
      class="ml-auto font-weight-light d-none d-xl-flex"
    >
      <NavLink samePage title="How It Works" to="how-it-works" />
      <NavLink samePage title="Benefits" to="benefits" />
      <NavLink samePage title="Features" to="features" />
    </b-navbar-nav>
    <b-navbar-nav
      v-else-if="employeeBenefitsPage"
      class="ml-auto font-weight-light d-none d-xl-flex"
    >
      <b-nav-item-dropdown
        text="Solutions"
        class="py-2 px-3"
        :class="{ 'sub-active': employeeBenefitsSolutionsSub }"
        right
      >
        <b-dropdown-item to="/employee-benefits/employers/"
          >For Employers</b-dropdown-item
        >
        <b-dropdown-item to="/employee-benefits/brokers/">
          For Brokers
        </b-dropdown-item>
        <b-dropdown-item to="/employee-benefits/providers"
          >For Providers</b-dropdown-item
        >
      </b-nav-item-dropdown>
      <b-nav-item-dropdown
        text="Resources"
        class="py-2 px-3"
        :class="{ 'sub-active': employeeBenefitsResourcesSub }"
        right
      >
        <b-dropdown-item to="/blog/">Blog</b-dropdown-item>
        <b-dropdown-item to="/employee-benefits/faq/">FAQ</b-dropdown-item>
      </b-nav-item-dropdown>
      <NavLink title="About" to="/about-us/" />
      <NavLink
        class="btn w-100 btn-secondary btn-large rounded-pill color-white"
        title="Contact Us"
        to="Contact"
      />
    </b-navbar-nav>
    <b-navbar-nav v-else class="ml-auto font-weight-light d-none d-xl-flex">
      <NavLink title="How It Works" to="/how-it-works/" />
      <b-nav-item-dropdown
        text="Benefits"
        class="py-2 px-3"
        :class="{ 'sub-active': subIsActive }"
        right
      >
        <b-dropdown-item :to="{ name: 'IndividualBenefits' }"
          >For Consumers</b-dropdown-item
        >
        <b-dropdown-item to="/lender-benefits/">
          For Lenders
        </b-dropdown-item>
        <b-dropdown-item to="/employee-benefits/"
          >For Employers</b-dropdown-item
        >
      </b-nav-item-dropdown>
      <NavLink title="Features" to="/features/" />
    </b-navbar-nav>
    <b-navbar-nav
      v-if="!employeeBenefitsPage"
      class="font-weight-light d-none d-lg-flex"
    >
      <SignupLinkButton :to="{ name: 'IndividualSignup' }" navItem
        >Sign Up Now!</SignupLinkButton
      >
      <li
        class="py-1 align-items-center"
        :class="[landingPage ? 'd-none' : 'd-flex']"
      >
        <div class="separator"></div>
      </li>
      <NavLink
        :class="{ 'd-none': landingPage }"
        title="Log In"
        externalLink
        :to="loginLink"
      />
    </b-navbar-nav>
  </div>
</template>

<script>
import NavLink from './NavLink'

export default {
  name: 'TopNavigation',

  components: {
    NavLink,
  },
  computed: {
    loginLink: function() {
      return this.$store.getters['member/getLoginLink']
    },
    subIsActive: function() {
      return this.$route.meta.benefits
    },
    landingPage: function() {
      return this.$route.name === 'SpecialOfferPage'
    },
    employeeBenefitsPage: function() {
      return this.$route.meta.employeeBenefits
    },
    employeeBenefitsSolutionsSub: function() {
      return this.$route.meta.emeployeeBenefitsSolutions
    },
    employeeBenefitsResourcesSub: function() {
      return this.$route.meta.emeployeeBenefitsResources
    },
  },
}
</script>
