<template>
  <div class="loader-mini">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'MiniLoader',
}
</script>
