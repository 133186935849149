<template>
  <b-modal
    id="sponsor-modal"
    hide-footer
    body-class="pt-2 pb-5 px-5"
    header-class="pt-3 px-3 pb-0 h-0 border-0"
  >
    <p class="h4 font-weight-bold mb-4">
      Please enter your sponsor code before proceeding
    </p>
    <form autocomplete="off">
      <b-form-input
        id="sponsor-code"
        v-model.lazy.trim="sponsorCode"
        debounce="500"
        type="text"
        maxlength="36"
        placeholder="Sponsor Code"
        class="mb-2"
      ></b-form-input>
    </form>
    <p v-if="errorMessage" class="text-danger font-weight-bold">
      <small>{{ errorMessage }}</small>
    </p>
    <div class="d-flex justify-content-between mt-4">
      <b-button
        variant="text"
        class="text-gray-600 font-weight-semibold"
        @click="continueClick(false)"
        >Continue without a code</b-button
      >
      <b-button
        :disabled="sponsorCodeInvalid"
        @click="continueClick(true)"
        variant="secondary"
        pill
        size="sm"
        class="px-5"
      >
        <MiniLoader v-if="modalLoading" />
        <template v-else>Continue</template>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'SponsorCodeModal',
  data() {
    return {
      sponsorCode: '',
      sponsorCodeInvalid: true,
      lastCheckedCode: '',
      errorMessage: '',
      typingTimeout: null,
      modalLoading: false,
    }
  },
  computed: {
    signupLink: function() {
      return this.$store.getters['member/getSignupLink']
    },
  },
  watch: {
    sponsorCode() {
      this.sponsorCodeLookup()
    },
  },
  methods: {
    continueClick(code) {
      if (code) {
        if (this.$gtm.enabled())
          this.$gtm.trackView(
            'ConsumerSignupSponsored',
            '/individual-signup/sponsored'
          )
        window.location = `${this.signupLink}&sponsorCodeString=${this.sponsorCode}`
      } else {
        this.$store.dispatch('member/loadDefault').then(() => {
          this.$bvModal.hide('sponsor-modal')
          this.$router.push({ name: 'IndividualSignup' })
        })
      }
    },
    sponsorCodeLookup() {
      this.sponsorCodeInvalid = true
      if (
        this.sponsorCode.length > 5 &&
        !this.$store.getters['member/isLoading']
      ) {
        this.modalLoading = true
        this.lastCheckedCode = this.sponsorCode
        this.errorMessage = ''
        this.$store
          .dispatch('member/validateSponsorCode', {
            sponsorCode: this.sponsorCode,
          })
          .then(() => {
            this.sponsorCodeInvalid = false
            this.modalLoading = false
          })
          .catch(error => {
            this.errorMessage = error.data.errors[0].message
            this.modalLoading = false
          })
          .finally(() => {
            if (this.lastCheckedCode !== this.sponsorCode)
              this.sponsorCodeLookup()
          })
      }
    },
  },
}
</script>
