<template>
  <header :class="{ 'employee-benefits-header': employeeBenefitsPage }">
    <b-navbar toggleable="lg" class="fixed-top">
      <b-navbar-brand v-if="!employeeBenefitsPage">
        <router-link exact to="/" :class="{ 'd-none': landingPage }">
          <Logo width="221" />
        </router-link>
        <Logo :class="{ 'd-none': !landingPage }" width="221" />
      </b-navbar-brand>

      <b-navbar-brand v-else>
        <router-link exact to="/employee-benefits/">
          <Logo width="221" />
          <p>EMPLOYEE <strong>FINANCIAL WELLNESS</strong></p>
        </router-link>
      </b-navbar-brand>

      <Cobrand
        v-if="!employeeBenefitsPage"
        :num="0"
        class="d-none d-lg-flex align-items-center border-left px-3 border-black mr-auto"
      />

      <b-navbar-toggle
        class="order-3 border-0"
        :class="[
          landingPage ? 'd-none' : 'd-block',
          employeeBenefitsPage ? 'd-xl-none' : '',
        ]"
        target="sidebar"
      ></b-navbar-toggle>

      <TopNavigation />
    </b-navbar>
    <Cobrand
      :num="1"
      class="d-flex d-lg-none align-items-center justify-content-center py-3 bg-white"
    />
  </header>
</template>

<script>
import TopNavigation from '@/components/navigation/TopNavigation'
import Logo from '@/components/svg/Logo'
import Cobrand from '@/components/common/Cobrand'

export default {
  name: 'Header',
  components: {
    TopNavigation,
    Cobrand,
    Logo,
  },
  computed: {
    landingPage: function() {
      return this.$route.name === 'SpecialOfferPage'
    },
    employeeBenefitsPage: function() {
      return this.$route.meta.employeeBenefits
    },
  },
}
</script>
