const state = {
  status: '',
  author_status: '', // Author lookup status so we don't overload the browser with needless api calls
  all_posts: {
    // This object holds all of the blog posts, with data on page and current page
    pages: 0,
    current_page: 1,
    posts_arr: [],
  },
  post: [], // Current, single post object - mostly just used when a post is navigated to directly
  all_categories: [], // Array of categories for easy lookup
  category_posts: [], // Array of objects for each category's posts ordered by category ID
  category_pages: [], // Array of objects for each category's page/current page info ordered by category ID
  authors: [], // Array of authors for easy lookup
}

export default state
