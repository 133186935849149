import router from '@/router'

const getters = {
  getAllPosts: state => state.all_posts.posts_arr,
  getAllCategories: state => state.all_categories,
  getCurrentPage: state => {
    if (
      router.currentRoute.params.id &&
      router.currentRoute.name === 'BlogList'
    ) {
      return state.category_pages[router.currentRoute.params.id].current_page
    } else {
      return state.all_posts.current_page
    }
  },
  getCategoryPosts: state => id => state.category_posts[id],
  getCategoryName: state => id => {
    const cat = state.all_categories.find(category => category.id == id)
    return cat ? cat.name : null
  },
  getAllAuthors: state => state.authors,
  getAuthorName: state => id => {
    let authorObj = state.authors.find(author => author.id === id)
    return authorObj.name
  },
  isAuthorLoading: state => state.author_status === 'loading',
  isAuthorLoaded: state => state.author_status === 'complete',
}

export default getters
