import Vue from 'vue'

const mutations = {
  SET_LOADING(state) {
    state.status = 'loading'
  },
  SET_NOTFOUND(state) {
    state.status = 'notfound'
  },
  SET_FINISHED(state) {
    state.status = 'complete'
  },
  SET_AUTHOR_STATUS(state, status) {
    state.author_status = status
  },
  SET_POSTS(state, { resp, pageCount, page }) {
    state.all_posts.posts_arr = resp.data
    state.all_posts.pages = pageCount
    state.all_posts.current_page = page
  },
  REMOVE_POSTS(state, categoryId) {
    if (categoryId) {
      state.category_posts.splice(categoryId)
    } else {
      state.all_posts.posts_arr = []
    }
  },
  SET_AUTHORS(state, authors) {
    state.authors = authors
  },
  SET_POST(state, post) {
    state.post = Object.assign({}, post)
  },
  SET_CATEGORIES(state, categories) {
    state.all_categories = categories
  },
  SET_CATEGORY_POSTS(state, { categoryId, resp, pageCount, page }) {
    const pageObj = {
      pages: pageCount,
      current_page: page,
    }
    Vue.set(state.category_posts, categoryId, resp.data)
    Vue.set(state.category_pages, categoryId, pageObj)
  },
  SET_POSTS_MEDIA(state, { mediaUrl, id, categoryId }) {
    if (categoryId) {
      Vue.set(state.category_posts[categoryId][id], 'media_url', mediaUrl)
    } else {
      Vue.set(state.all_posts.posts_arr[id], 'media_url', mediaUrl)
    }
  },
  SET_POST_MEDIA(state, { mediaUrl }) {
    Vue.set(state.post, 'media_url', mediaUrl)
  },
}

export default mutations
