<template>
  <div id="hero" :style="cssProps">
    <div class="background-image"></div>
    <div class="hero-content h-100">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullWidthImageHero',
  props: {
    image: String,
    mobileImage: String,
    height: {
      type: String,
      default: '500px',
    },
    mobileHeight: {
      type: String,
      default: '440px',
    },
    backgroundPosition: {
      type: String,
      default: 'top center',
    },
    backgroundPositionMobile: {
      type: String,
      default: 'top center',
    },
  },
  computed: {
    cssProps() {
      let styles = {}
      if (this.image) styles['--hero-background'] = `url(${this.image})`
      if (this.mobileImage)
        styles['--hero-mobile-background'] = `url(${this.mobileImage})`
      if (this.height) styles['--hero-height'] = `${this.height}`
      if (this.mobileHeight)
        styles['--hero-mobile-height'] = `${this.mobileHeight}`
      if (this.backgroundPosition)
        styles['--hero-background-position'] = `${this.backgroundPosition}`
      if (this.backgroundPositionMobile)
        styles[
          '--hero-mobile-background-position'
        ] = `${this.backgroundPositionMobile}`

      return styles
    },
  },
}
</script>

<style lang="scss" scoped>
#hero {
  position: relative;
  padding: 0 15px;
  height: var(--hero-mobile-height);

  .background-image {
    position: absolute;
    background-image: var(--hero-mobile-background);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: var(--hero-mobile-background-position);
    background-color: $primary-lighten;
    width: 100%;
    height: var(--hero-mobile-height);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .hero-content {
    background: #fff;
  }
}

@media (min-width: 768px) {
  #hero {
    padding: 0;
    min-height: var(--hero-height);

    .background-image {
      background-size: cover;
      background-image: var(--hero-background);
      height: var(--hero-height);
      background-position: var(--hero-background-position);
    }
  }
}
</style>
