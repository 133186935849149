const mutations = {
  SET_LOADING(state) {
    state.status = 'loading'
  },
  SET_FINISHED(state) {
    state.status = 'complete'
  },
  SET_TRACKING(state, { tracking, trackingToken }) {
    state.tracking = tracking
    state.tracking_token = trackingToken
  },
  SET_UTM_PARAM(state, { token, val }) {
    const utmTokens = {
      utm_source: 'source',
      utm_medium: 'medium',
      utm_campaign: 'name',
      utm_term: 'term',
      utm_content: 'content',
    }
    state.campaign[utmTokens[token]] = val
  },
  SET_URL_PARAM(state, { token, val }) {
    const dlTokens = {
      aid: 'affiliate_id',
      adid: 'ad_creative_id',
      tid: 'template_id',
      sid: 'site_id',
      cid: 'campaign_id',
    }
    state[dlTokens[token]] = val
  },
  SET_PID(state, PID) {
    state.pid = PID
    state.pid_loaded = true
    localStorage.setItem('PID', PID)
  },
  SET_ADVOCACY_PID(state) {
    if (state.advocacy_lookup[state.pid]) {
      state.advocacy_pid = state.advocacy_lookup[state.pid]
      return
    }
    state.advocacy_pid = '23736'
  },
  SET_COBRAND(state, cobrandObj) {
    state.cobrand = Object.assign({}, cobrandObj)
  },
  SET_TOKEN(state, token) {
    state.tracking_token = token
  },
  REMOVE_COBRAND(state) {
    state.cobrand = {}
  },
  SET_MEMBER_PLANS(state, membershipObj) {
    state.member_plans = Object.assign({}, membershipObj)
  },
  SET_QL_VALID(state, valid) {
    state.valid_quicklink = valid
  },
  ADD_PAGEVIEW_TO_QUEUE(state, data) {
    state.pageviewQueue.push(data)
  },
  CLEAR_PAGEVIEW_QUEUE(state) {
    state.pageviewQueue.splice(0)
  },
}

export default mutations
