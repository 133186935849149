<template>
  <div class="loader">
    <div class="loader--text">{{ loadingText }}</div>
    <div class="dots" />
    <div class="dots" />
    <div class="dots" />
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    loadingText: {
      default: 'Loading',
      type: String,
    },
  },
}
</script>
