import BlogService from '@/services/BlogService'

const actions = {
  getAllCategories({ commit }) {
    commit('SET_LOADING')

    return new Promise((resolve, reject) => {
      BlogService.getCategories()
        .then(resp => {
          commit('SET_CATEGORIES', resp.data)
          resolve('Success')
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => {
          commit('SET_FINISHED')
        })
    })
  },
  getAllPosts({ commit, dispatch }, { categoryId = '', page = 1 }) {
    commit('SET_LOADING')
    commit('REMOVE_POSTS', categoryId)

    return new Promise((resolve, reject) => {
      BlogService.getPosts(categoryId, page)
        .then(resp => {
          const pageCount = parseInt(resp.headers['x-wp-totalpages'])
          if (categoryId) {
            commit('SET_CATEGORY_POSTS', { categoryId, resp, pageCount, page })
          } else {
            commit('SET_POSTS', { resp, pageCount, page })
          }
          return resp.data
        })
        .then(posts => {
          let promises = []

          for (let id in posts) {
            if (posts[id].featured_media) {
              promises.push(
                dispatch('getMedia', posts[id].featured_media).then(
                  mediaUrl => {
                    commit('SET_POSTS_MEDIA', { mediaUrl, id, categoryId })
                  }
                )
              )
            }
          }
          return Promise.all(promises)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => {
          resolve('Success')
          commit('SET_FINISHED')
        })
    })
  },
  getAllAuthors({ commit }) {
    commit('SET_AUTHOR_STATUS', 'loading')

    return new Promise((resolve, reject) => {
      BlogService.getAuthors()
        .then(resp => {
          commit('SET_AUTHORS', resp.data)
          commit('SET_AUTHOR_STATUS', 'complete')
          resolve('Success')
        })
        .catch(err => {
          reject(err)
          commit('SET_AUTHOR_STATUS', 'error')
        })
    })
  },
  getPost({ commit, dispatch, state }, id) {
    commit('SET_LOADING')
    const cachedPost = state.all_posts.posts_arr.find(post => post.id === id)

    return new Promise((resolve, reject) => {
      if (cachedPost) {
        commit('SET_POST', cachedPost)
        resolve('Success')
        commit('SET_FINISHED')
      } else {
        BlogService.getPost(id)
          .then(resp => {
            if (resp.data.featured_media) {
              dispatch('getMedia', resp.data.featured_media).then(mediaUrl => {
                commit('SET_POST', resp.data)
                commit('SET_POST_MEDIA', { mediaUrl, id })
              })
            } else {
              commit('SET_POST', resp.data)
            }
          })
          .catch(err => {
            reject(err)
            commit('SET_NOTFOUND')
          })
          .finally(() => {
            resolve('Success')
            commit('SET_FINISHED')
          })
      }
    })
  },
  getMedia({ state }, id) {
    const cachedPost = state.all_posts.posts_arr.find(
      post => post.featured_media === id
    )
    return new Promise((resolve, reject) => {
      if (cachedPost && cachedPost.media_url) {
        resolve(cachedPost.media_url)
      } else {
        BlogService.getMedia(id)
          .then(resp => {
            resolve(resp.data.source_url)
          })
          .catch(err => {
            reject(err)
          })
      }
    })
  },
}

export default actions
