const mutations = {
  updatePageSchema(state, fetchData) {
    state.schema = fetchData
  },
  resetDefaultSchema(state) {
    state.schema = state.defaultSchema
  },
}

export default mutations
