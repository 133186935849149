const FidesScript = {
  fidesUrl: 'https://consumerdirect.fides-cdn.ethyca.com/fides.js',
  fidesEnabled: 1,
  loadScript() {
    const doc = document,
      script = doc.createElement('script')
    script.src = this.fidesUrl
    doc.head.insertBefore(script, doc.head.firstChild)
    script.addEventListener('load', function() {
      window.Fides.gtm()
      if (process.env.VUE_APP_SEGMENT_ENABLED === '1') {
        window.analytics.identify({
          dataSalesConsent: window.Fides.consent.data_sales_and_sharing,
        })
      }
    })
  },
  /**
   * Check if Fides script is in the document
   * @return {boolean}
   */
  hasScript() {
    return Array.from(document.getElementsByTagName('script')).some(script =>
      script.src.includes(this.fidesUrl)
    )
  },
  install() {
    if (this.fidesEnabled && !this.hasScript()) this.loadScript()
  },
}

export default FidesScript
