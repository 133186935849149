<template>
  <div v-if="isCobrand">
    <p class="pr-1 mb-0 text-text-black subhead">
      <em>Partnered<br />with</em>
    </p>
    <button class="btn btn-link p-0" :id="`cobrand${num}-popover`">
      <img
        :src="cobrand.logoUrl"
        class="cobrand-img"
        :alt="`${cobrand.title} ${cobrand.title2}`"
      />
    </button>
    <b-popover
      :target="`cobrand${num}-popover`"
      triggers="click blur"
      placement="bottom"
    >
      <template v-slot:title>Special Message </template>
      <div class="d-flex">
        <div>
          <img
            :src="cobrand.appIconUrl"
            :alt="`${cobrand.title} ${cobrand.title2}`"
          />
        </div>
        <div>{{ cobrand.title }}<br />{{ cobrand.title2 }}</div>
      </div>
      <div>
        <p>{{ cobrand.description }}</p>
        <p v-if="cobrand.description2">{{ cobrand.description2 }}</p>
        <b-button
          pill
          size="sm"
          variant="secondary"
          :href="cobrand.websiteUrl"
          target="_blank"
          class="w-100"
        >
          Learn More &raquo;
        </b-button>
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: 'Cobrand',
  props: { num: Number },
  data() {
    return {}
  },
  computed: {
    cobrand: function() {
      return this.$store.getters['member/getCobrand']
    },
    isCobrand: function() {
      return Object.keys(this.cobrand).length
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar .d-flex {
  width: 53px;
}
.subhead {
  line-height: 16px;
  font-size: 12px;
}
.cobrand-img {
  max-height: 54px;
  height: 100%;
  width: auto;
}
</style>
