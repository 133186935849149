<template>
  <li v-if="externalLink" class="nav-item px-3 py-2">
    <a
      :href="to"
      class="nav-link"
      :class="{ 'font-weight-semibold text-primary': emphasis }"
    >
      <i class="fa" :class="icon" v-if="icon" />{{ title }}</a
    >
  </li>
  <b-nav-item
    v-else-if="samePage"
    exact
    @click="scroll(to)"
    class="px-3 py-2"
    :class="{ 'font-weight-semibold text-primary': emphasis }"
  >
    <i class="fa" :class="icon" v-if="icon" />
    {{ title }}</b-nav-item
  >
  <b-nav-item
    v-else
    exact
    :to="to"
    class="px-3 py-2"
    :class="{ 'font-weight-semibold text-primary': emphasis }"
  >
    <i class="fa" :class="icon" v-if="icon" />
    {{ title }}</b-nav-item
  >
</template>

<script>
export default {
  name: 'NavLink',
  props: {
    title: String,
    emphasis: Boolean,
    externalLink: Boolean,
    samePage: Boolean,
    to: [String, Object],
    icon: String,
  },
  methods: {
    scroll(id) {
      const offset = 100
      const el = document.getElementById(id)
      window.scroll({ top: el.offsetTop - offset, left: 0, behavior: 'smooth' })
    },
  },
}
</script>
