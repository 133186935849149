import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtm from 'vue-gtm'
import Meta from 'vue-meta'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import Vue2TouchEvents from 'vue2-touch-events'

const sanitizeHtml = require('sanitize-html')
Vue.prototype.$sanitize = sanitizeHtml

import DateFilter from './filters/date'
Vue.filter('date', DateFilter)

// Custom directives
import scrollWatcher from './directives/vue-in-viewport-directive'
import clickAway from './directives/vue-click-away'
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective)
Vue.directive('scroll-watch', scrollWatcher)
Vue.directive('click-away', clickAway)

//Global Components import
import './components/GlobalComponents'

//CSS import
import '@/assets/styles/global.scss'

Vue.config.productionTip = false
Vue.config.ignoredElements = [/dotlottie-\w*/]

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  enabled:
    window.__PRERENDER_INJECTED || process.env.NODE_ENV === 'development'
      ? false
      : true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: process.env.NODE_ENV === 'production' ? false : true, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
})

Vue.use(Meta)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(Vue2TouchEvents)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
