import axios from 'axios'

export default {
  scoremaster() {
    return axios.create({
      baseURL: process.env.VUE_APP_API_LOCATION,
      headers: { Accept: 'application/json' },
    })
  },
  greenrope() {
    return axios.create({
      baseURL: 'https://app.greenrope.com/j1.pl',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
    })
  },
  blog() {
    return axios.create({
      baseURL: `${process.env.VUE_APP_WORDPRESS_API}wp-json/wp/v2`,
    })
  },
}
