import Api from '@/services/Api'

export default {
  startTracking(pid) {
    return Api.scoremaster().get(`track?PID=${pid}`)
  },
  lookupCampaign(PID, QL) {
    const query = QL ? 'quickLinkKeyword' : 'PID'
    const value = QL ? QL : PID
    return Api.scoremaster().get(
      `signup/campaign?clientKey=${process.env.VUE_APP_SM_API_KEY}&${query}=${value}`
    )
  },
  getTrackingToken() {
    return Api.scoremaster().get(
      `signup/start?clientKey=${process.env.VUE_APP_SM_API_KEY}`
    )
  },
  validateSponsorCode(sponsorCode, trackingToken) {
    return Api.scoremaster().get(
      `signup/validate/sponsor-code?clientKey=${process.env.VUE_APP_SM_API_KEY}&sponsorCode=${sponsorCode}&trackingToken=${trackingToken}`
    )
  },
}
