<template>
  <footer class="mt-5 mt-lg-7">
    <b-container fluid class="bg-gray-100 py-4" id="footer-section1">
      <b-row>
        <b-col>
          <b-container>
            <b-row>
              <b-col class="col-sm-5 col-md-4 d-none d-md-flex">
                <ul class="list-inline links">
                  <li class="pb-2 mr-0 list-inline-item">
                    <router-link to="/about-us/">About Us</router-link>
                  </li>
                  <li class="pb-2 mr-0 list-inline-item">
                    <a
                      :href="
                        `${memberUrl}help/terms-and-privacy/service-agreement.htm`
                      "
                      target="_blank"
                      >Service Agreement</a
                    >
                  </li>
                  <li class="pb-2 mr-0 list-inline-item">
                    <a :href="`${memberUrl}help/contact-us/`" target="_blank"
                      >Contact Us</a
                    >
                  </li>
                </ul>
              </b-col>
              <b-col cols="12" md="5">
                <p class="mb-1 font-weight-bold">
                  Customer Service based in the US
                </p>
                <p class="mb-1">For Questions Call: (877) 210-0180</p>
                <p class="mb-1">
                  We're committed to providing you with the best possible
                  customer service.
                </p>
                <p class="mb-1">
                  Available: Mon - Fri 8:00AM - 8:00PM EST
                </p>
              </b-col>
              <b-col class="ml-lg-auto col-auto mt-4 mt-md-0">
                <ul class="list-inline">
                  <li class="list-inline-item">
                    <a
                      href="https://www.facebook.com/realscoremaster"
                      target="_blank"
                      ><IconFacebook
                        style="height: 30px; width: 30px;"
                        class="icon icon-gray-600"
                    /></a>
                  </li>
                  <li class="list-inline-item">
                    <a href="https://twitter.com/scoremaster" target="_blank"
                      ><IconTwitter
                        style="height: 30px; width: 30px;"
                        class="icon icon-gray-600"
                    /></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="https://www.instagram.com/scoremaster/"
                      target="_blank"
                      ><IconInstagram
                        style="height: 30px; width: 30px;"
                        class="icon icon-gray-600"
                    /></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      href="https://www.youtube.com/channel/UCHRR-q_oQe8O4qfWVBFQFjA"
                      target="_blank"
                      ><IconYoutube
                        style="height: 30px; width: 30px;"
                        class="icon icon-gray-600"
                    /></a>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="bg-gray-600 py-4" id="footer-section2">
      <b-row>
        <b-col>
          <b-container>
            <b-row>
              <b-col>
                <ul class="list-inline links">
                  <li class="list-inline-item mr-0">
                    <a
                      class="text-white"
                      :href="
                        `${memberUrl}help/terms-and-privacy/service-agreement.htm`
                      "
                      target="_blank"
                      >Service Agreement</a
                    >
                  </li>
                  <li class="list-inline-item mr-0">
                    <a
                      class="text-white"
                      :href="`${memberUrl}help/terms-and-privacy/site-use.htm`"
                      target="_blank"
                      >Terms of Use</a
                    >
                  </li>
                  <li class="list-inline-item mr-0">
                    <a
                      class="text-white"
                      :href="`${memberUrl}help/resources/security.htm`"
                      target="_blank"
                      >Security</a
                    >
                  </li>
                  <li class="list-inline-item mr-0">
                    <a
                      class="text-white"
                      :href="
                        `${memberUrl}help/terms-and-privacy/privacy-policy.htm`
                      "
                      target="_blank"
                      >Privacy Policy</a
                    >
                  </li>
                  <li
                    class="list-inline-item mr-0"
                    :class="{ 'd-none': landingPage }"
                  >
                    <router-link class="text-white" to="/lender-benefits/"
                      >I am a Lender</router-link
                    >
                  </li>
                  <li
                    class="list-inline-item mr-0"
                    :class="{ 'd-none': landingPage }"
                  >
                    <router-link class="text-white" to="/employee-benefits/"
                      >I am an Employer</router-link
                    >
                  </li>
                </ul>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p id="copyright-notice" class="text-white">
                  &copy; ScoreMaster.com<sup>&reg;</sup> - Powered by the
                  <a
                    class="text-white"
                    href="https://consumerdirect.com/contact.html"
                    target="_blank"
                    >ConsumerDirect<sup>&reg;</sup></a
                  >
                  Platform - All Rights Reserved
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import IconFacebook from '@/assets/svg/icon_Facebook.svg'
import IconTwitter from '@/assets/svg/icon_Twitter.svg'
import IconInstagram from '@/assets/svg/icon_Instagram.svg'
import IconYoutube from '@/assets/svg/icon_Youtube.svg'

export default {
  name: 'Footer',
  components: {
    IconFacebook,
    IconTwitter,
    IconInstagram,
    IconYoutube,
  },
  data() {
    return {
      memberUrl: process.env.VUE_APP_MEMBER_URL,
    }
  },
  computed: {
    landingPage: function() {
      return this.$route.name === 'LandingPage'
    },
  },
}
</script>

<style lang="scss" scoped>
#footer-section1 .links.list-inline li {
  white-space: nowrap;
  width: 49%;
}
#footer-section2 .links.list-inline li:not(:last-child):after {
  content: '|';
  color: var(--white);
  padding: 0 10px;
}
</style>
