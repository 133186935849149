import CampaignService from '@/services/CampaignService'
import Router from '@/router'
import Cookie from '@/helpers/cookies'

const actions = {
  lookupCampaign({ commit, dispatch }, { PID, QL }) {
    // Show loading until campaign call resolves if:
    // PID is unknown or the quick link isn't a defined landing page
    const showLoading = QL && !Router.currentRoute.meta.landing
    if (!showLoading) {
      commit('SET_FINISHED')
    }

    return new Promise(resolve => {
      CampaignService.lookupCampaign(PID, QL)
        .then(resp => {
          const PID = resp.data.PID
          const cobrandObj = resp.data.cobrand ? resp.data.cobrand : {}
          const membershipObj = resp.data.memberPlans
            ? resp.data.memberPlans
            : {}

          commit('SET_PID', PID)
          commit('SET_ADVOCACY_PID', PID)
          commit('SET_COBRAND', cobrandObj)
          commit('SET_MEMBER_PLANS', membershipObj)
          if (QL) commit('SET_QL_VALID', true)

          dispatch('startTracking', PID).then(() => {
            if (this._vm.$gtm.enabled()) dispatch('pushDataLayer')
            dispatch('pushSegmentPageView')
          })
          if (showLoading) {
            Router.replace({ name: 'Home' }).then(() => commit('SET_FINISHED'))
          }
        })
        .catch(() => {
          if (QL) commit('SET_QL_VALID', false)
          dispatch('loadDefault', PID)
        })
        .finally(() => {
          resolve()
        })
    })
  },
  startTracking({ commit }, PID) {
    return new Promise(resolve => {
      let track = Cookie.get('TRACKING')
      if (track) {
        commit('SET_TRACKING', {
          tracking: encodeURI(track),
        })
        resolve()
      } else {
        CampaignService.startTracking(PID).then(resp => {
          commit('SET_TRACKING', {
            tracking: resp.data.tracking,
            trackingToken: resp.data.trackingToken,
          })

          Cookie.set('TRACKING', resp.data.tracking, {
            samesite: 'strict',
            domain: 'scoremaster.com',
            days: 10000,
          })
          resolve()
        })
      }
    })
  },
  pushDataLayer({ state }) {
    const data = {
      event: 'set-tracking-ids', // event name
      product_code: 'sm',
    }
    data.anonymous_id = state.tracking
    data.publisher_id = state.pid
    if (state.affiliate_id) data.affiliate_id = state.affiliate_id
    if (state.ad_creative_id) data.ad_creative_id = state.ad_creative_id
    if (state.campaign_id) data.campaign_id = state.campaign_id
    if (state.site_id) data.site_id = state.site_id
    if (state.template_id) data.template_id = state.template_id

    this._vm.$gtm.dataLayer().push(data)
  },
  async validateSponsorCode({ state, dispatch }, { sponsorCode }) {
    if (!state.tracking_token) await dispatch('getTrackingToken')

    return new Promise((resolve, reject) => {
      CampaignService.validateSponsorCode(sponsorCode, state.tracking_token)
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  pushSegmentPageView({ commit, getters, state }, data) {
    if (getters.isPIDLoaded) {
      // check for queued up pageviews - these were pageviews that occurred before the campaign api call completed
      let queue = state.pageviewQueue
      if (queue.length > 0) {
        for (let pageview of queue) {
          window.analytics.page({ ...getters.getTrackingData, ...pageview })
        }
      }
      if (data) window.analytics.page({ ...getters.getTrackingData, ...data })
      else if (
        Object.keys(getters.getUtmTrackingData).length > 0 &&
        queue.length > 1
      ) {
        window.analytics.track(
          'Update Campaign',
          {},
          {
            campaign: getters.getUtmTrackingData,
          }
        )
      }
      commit('CLEAR_PAGEVIEW_QUEUE')
    } else {
      // campaign call hasn't completed yet, queue this pageview for after that occurs
      commit('ADD_PAGEVIEW_TO_QUEUE', data)
    }
  },
  getTrackingToken({ commit }) {
    return new Promise((resolve, reject) => {
      CampaignService.getTrackingToken()
        .then(resp => {
          if (resp.data.trackingToken) {
            commit('SET_TOKEN', resp.data.trackingToken)
            resolve()
          } else reject('Server Error')
        })
        .catch(() => {
          reject()
        })
    })
  },
  loadDefault({ commit, dispatch }, PID) {
    // If pid that failed wasn't the default, then try again with default
    if (PID !== process.env.VUE_APP_DEFAULT_PID) {
      dispatch('lookupCampaign', {
        PID: process.env.VUE_APP_DEFAULT_PID,
        QL: null,
      })
    } else {
      commit('SET_PID', PID)
      commit('SET_ADVOCACY_PID', PID)
      commit('REMOVE_COBRAND')
      commit('SET_QL_VALID', false)
    }
  },
}

export default actions
